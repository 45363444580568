<template>
  <div class="page-wrapper">
    <div class="header">
      <div class="header-item">
        <div class="item-body" style="color: #26d9ff">
          {{ countDetail.sendCount }} 条
        </div>
        <div class="title">今日发送号码数</div>
      </div>
      <div class="header-item">
        <div class="item-body" style="color: #6c50f3">
          {{ countDetail.billingCount }} 条
        </div>
        <div class="title">今日发送成功数</div>
      </div>
      <div class="header-item">
        <div class="item-body" style="color: #00ca95">
          {{ countDetail.num }} 条
        </div>
        <div class="title">用户剩余条数</div>
      </div>
      <!-- <div class="header-item">
        <div class="item-body" style="color: #409eff">
          {{ countDetail.four }} 条
        </div>
        <div class="title">余额</div>
      </div> -->
    </div>
    <div class="body-box">
      <!-- 表格 -->
      <div class="box-item">
        <div class="table-condition">
          <el-form :inline="true" :model="formTable" class="demo-form-inline">
            <el-form-item label="">
              <!-- <el-input
                v-model="formTable.userName"
                placeholder="请输入客户名称"
              ></el-input> -->
              <el-date-picker
                v-model="formTable.createTime"
                type="month"
                placeholder="请选择统计日期"
                format="YYYY-MM"
                value-format="YYYY-MM"
                @change="handleChangeTable"
              >
              </el-date-picker>
              <span class="tips">默认显示本月的数据</span>
            </el-form-item>
            <el-form-item>
              <!-- <el-button type="primary" @click="onSubmitTable">查询</el-button> -->
              <!-- <el-button @click="cancelSubmitTable">重置</el-button> -->
              <el-button type="primary" @click="exportExcel">导出</el-button>
            </el-form-item>
          </el-form>
        </div>
        <el-table
          :data="tableData"
          border
          max-height="460"
          :header-cell-style="{ background: '#E5F0FF' }"
          style="width: 100%"
        >
          <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
          <el-table-column
            type="index"
            align="center"
            label="序号"
            width="80"
          ></el-table-column>
          <el-table-column prop="sentTime" label="发送时间"> </el-table-column>
          <el-table-column prop="sendCount" label="发送号码数">
          </el-table-column>
          <el-table-column prop="moneyNum" label="发送计费数">
          </el-table-column>
          <el-table-column prop="billingCount" label="发送成功数">
          </el-table-column>
        </el-table>
        <div class="btn-area">
          <el-pagination
            background
            @size-change="sizeChangeHandler"
            @current-change="currentChangeHandler"
            :current-page="pagination.pageNo"
            :page-sizes="[10, 15, 20]"
            :page-size="pagination.length"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pagination.total"
          ></el-pagination>
        </div>
      </div>
      <!-- 统计图 -->
      <div class="box-item">
        <div class="table-condition">
          <el-form :inline="true" :model="formChart" class="demo-form-inline">
            <el-form-item label="">
              <el-date-picker
                v-model="formChart.createTime"
                type="month"
                placeholder="请选择统计日期"
                format="YYYY-MM"
                value-format="YYYY-MM"
                @change="handleChangeChart"
              >
              </el-date-picker>
              <span class="tips">默认显示本月的数据</span>
              <!-- ~
              <el-date-picker
                v-model="formChart.maxMonth"
                type="month"
                placeholder="请选择结束日期"
                format="yyyy-MM"
                value-format="yyyy-MM"
              >
              </el-date-picker> -->
            </el-form-item>
            <el-form-item>
              <!-- <el-button type="primary" @click="onSubmitChart">查询</el-button>
              <el-button @click="cancelSubmitChart">重置</el-button> -->
            </el-form-item>
          </el-form>
        </div>
        <div ref="numCensue" id="numCensue"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { showLoading, hideLoading } from "../../utils/loading";
import echarts from "echarts";
const year = new Date().getFullYear();
const month =
  new Date().getMonth() + 1 < 10
    ? "0" + (new Date().getMonth() + 1)
    : new Date().getMonth() + 1;
const days = new Date(year, month, 0).getDate();
const currentDefaultMonthList = [];
console.log(year, month, days, "天数");
for (var i = 0; i < days; i++) {
  if (i < 9) {
    currentDefaultMonthList[i] = year + "-" + month + "-" + "0" + (i + 1);
  } else {
    currentDefaultMonthList[i] = year + "-" + month + "-" + (i + 1);
  }
}

export default {
  name: "messageChart",
  data() {
    return {
      currentMonthList: currentDefaultMonthList,
      countDetail: {
        sendCount: 0,
        billingCount: 0,
        num: 0,
      },
      page: 1,
      tableData: [],
      count: 0,
      formTable: {
        createTime: "",
      },
      pagination: {
        total: 0, //总条数
        pageNo: 1, // 页数
        length: 15, // 每页的条数
      }, // 分页控件
      formChart: {
        createTime: "",
        // minMonth: "",
        // maxMonth: "",
        // minMonth: currentYear + "-01",
        // maxMonth: currentYear + "-12",
      },
    };
  },
  mounted() {
    // this.queryDeviceSaleNum();
    this.getNum();
    this.getCount();
    this.getTableData();
    this.getChartData();
  },
  methods: {
    // 获取一个月有几天
    getMonthList(year, month) {
      this.currentMonthList = [];
      let days = new Date(year, month, 0).getDate();
      // console.log(year, month, days, "天数");
      for (var i = 0; i < days; i++) {
        this.currentMonthList[i] = year + "-" + month + "-" + (i + 1);
        if (i < 9) {
          this.currentMonthList[i] = year + "-" + month + "-" + "0" + (i + 1);
        } else {
          this.currentMonthList[i] = year + "-" + month + "-" + (i + 1);
        }
      }

      // console.log(this.currentMonthList);
    },
    // 获取列表数据
    getNum() {
      this.$http
        .request("getNumber", {})
        .then((res) => {
          // console.log(res, "000");
          this.countDetail.num = res.result;
        })
        .catch(() => {});
    },
    getCount() {
      this.$http
        .request("getStatistics", {})
        .then((res) => {
          // console.log(res, "000");
          this.countDetail.billingCount = res.result.billingCount;
          this.countDetail.sendCount = res.result.sendCount;
        })
        .catch(() => {});
    },
    getTableData(condition) {
      let postData = {
        page: this.pagination.pageNo,
        limit: this.pagination.length,
      };
      if (condition && condition.createTime) {
        postData.createTime = new Date(condition.createTime);
      } else {
        postData.createTime = new Date();
      }
      showLoading();
      this.$http
        .request("getDataStatistics", postData)
        .then((res) => {
          hideLoading();
          // console.log(res, "000");
          this.tableData = res.result.data;
          this.pagination.total = res.result.count;
        })
        .catch(() => {});
    },
    getChartData(condition) {
      const elem = this.$echarts.init(this.$refs.numCensue);
      let dataListb = [],
        dataLists = [];
      let postData = {};
      if (condition && condition.createTime) {
        postData.createTime = new Date(condition.createTime);
      } else {
        postData.createTime = new Date();
      }
      showLoading();
      this.$http
        .request("getDataStatistics", postData)
        .then((res) => {
          hideLoading();
          // console.log(res, "000");
          this.currentMonthList.forEach((item, i) => {
            // console.log(item, "2222222222");
            res.result.data.forEach((elem, e) => {
              // console.log(elem, "3333333333");
              if (elem.sentTime == item) {
                dataListb[i] = elem.billingCount;
                dataLists[i] = elem.sendCount;
              }
            });
            if (dataListb[i] == undefined) {
              dataListb[i] = 0;
            }
            if (dataLists[i] == undefined) {
              dataLists[i] = 0;
            }
          });
          // console.log(
          //   this.currentMonthList,
          //   dataLists,
          //   dataListb,
          //   "8888888888"
          // );
          let option = {
            color: ["#409EFF"],
            grid: {
              top: "15%",
              left: "6%",
              right: "8%",
              bottom: "25%",
            },
            legend: {
              show: true,
              // orient: "hi",
              x: "center", //可设定图例在左、右、居中
              y: "top", //可设定图例在上、下、居中
              data: ["发送号码数", "成功条数"],
            },
            tooltip: {
              trigger: "axis",
            },
            dataZoom: [
              {
                type: "inside",
                start: 0,
                end: 100,
              },
              {
                start: 0,
                end: 10,
                bottom: 22,
                // height: 15,
              },
            ],
            xAxis: {
              type: "category",
              name: "日期",
              // axisLabel: { rotate: -30 },
              data: this.currentMonthList,
            },
            yAxis: {
              type: "value",
              name: "数量",
              splitLine: {
                show: true,
                lineStyle: {
                  color: "rgba(255,255,255,0.1)",
                },
              },
              axisLine: {
                show: true,
              },
              axisLabel: {
                show: true,
                textStyle: {
                  color: "#606266",
                },
              },
              axisTick: {
                show: true,
              },
            },
            series: [
              {
                name: "发送号码数",
                data: dataLists,
                type: "bar",
                itemStyle: {
                  normal: {
                    //这里是重点
                    color: "#26D9FF",
                  },
                },
              },
              {
                name: "成功条数",
                data: dataListb,
                type: "bar",
                itemStyle: {
                  normal: {
                    //这里是重点
                    color: "#6C50F3",
                  },
                },
              },
            ],
          };
          elem.setOption(option);
        })
        .catch(() => {});
    },
    // 换每页条数
    sizeChangeHandler(length) {
      this.pagination.length = length;
      this.getTableData();
    },
    // 换页
    currentChangeHandler(pageNo) {
      this.pagination.pageNo = pageNo;
      this.getTableData();
    },
    // 表格-查询
    handleChangeTable(val) {
      console.log(val, "00000");
      const postData = {};
      for (let i in this.formTable) {
        if (this.formTable[i]) {
          postData[i] = this.formTable[i];
        }
      }
      this.getTableData(postData);
    },
    // onSubmitTable() {
    //   const postData = {};
    //   for (let i in this.formTable) {
    //     if (this.formTable[i]) {
    //       postData[i] = this.formTable[i];
    //     }
    //   }
    //   this.getTableData(postData);
    // },
    // cancelSubmitTable() {
    //   for (let i in this.formTable) {
    //     this.formTable[i] = "";
    //   }
    //   this.getTableData();
    // },
    // 导出
    exportExcel() {
      let postData = {
        page: this.pagination.pageNo,
        limit: this.pagination.length,
      };
      if (this.formTable.createTime) {
        postData.createTime = new Date(this.formTable.createTime);
      } else {
        postData.createTime = new Date();
      }
      this.$http
        .request("queryTaskTemplateReport", postData)
        .then((res) => {
          console.log(res, "导出11111111");
          // let fileUrl = res.result.fileUrl;
          // window.location.href = fileUrl;
        })
        .catch(() => {});
    },
    // 图表-查询
    handleChangeChart(val) {
      const postData = {};
      for (let i in this.formChart) {
        if (this.formChart[i]) {
          postData[i] = this.formChart[i];
        }
      }
      if (val) {
        this.getChartData(postData);
        let arr = postData.createTime.split("-");
        this.getMonthList(arr[0], arr[1]);
      } else {
        // 图表横坐标重置
        this.currentMonthList = currentDefaultMonthList;
        this.getChartData();
      }
    },
    // onSubmitChart() {
    //   const postData = {};
    //   for (let i in this.formChart) {
    //     if (this.formChart[i]) {
    //       postData[i] = this.formChart[i];
    //     }
    //   }
    //   this.getChartData(postData);
    //   let arr = postData.createTime.split("-");
    //   this.getMonthList(arr[0], arr[1]);
    //   console.log(arr, postData, "0000");
    // },
    // cancelSubmitChart() {
    //   // 图表横坐标重置
    //   this.currentMonthList = currentDefaultMonthList;
    //   this.getChartData();
    // },

    // 图表
    queryDeviceSaleNum(condition) {
      const elem = this.$echarts.init(this.$refs.numCensue);
      let dataList = [];
      let postData = {
        // minMonth: currentYear + "-01",
        // maxMonth: currentYear + "-12",
        ...this.formChart,
      };
      if (condition && condition.createTime) {
        postData.createTime = condition.createTime;
      }

      // this.comm.ajax(
      //   this.urls.monthUserChart,
      //   "post",
      //   postData,
      //   (res) => {
      //     this.currentMonthList.forEach((item, i) => {
      //       res.forEach((elem, e) => {
      //         if (elem.yearMonth == item) {
      //           dataList[i] = elem.total;
      //         }
      //       });
      //       if (dataList[i] == undefined) {
      //         dataList[i] = 0;
      //       }
      //     });

      // },
      // (error) => {
      //   console.log(error);
      // }
      // );
    },
  },
};
</script>
<style lang="scss" scoped>
.page-wrapper {
  height: 125%;
  padding: 10px;
  box-sizing: border-box;
  // overflow-y: scroll;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header-item {
      width: 25%;
      height: 100px;
      background-color: #fff;
      box-shadow: 0 0 3px 1px #d3d3d3;
      border-radius: 10px;
      text-align: center;
      .item-body {
        margin: 15px 0 5px;
        font-size: 25px;
        color: #3c3c3c;
        font-weight: bold;
      }
      .title {
        color: #333;
        font-size: 20px;
      }
    }
  }
  .body-box {
    height: 100%;
    padding: 20px 0;
    box-sizing: border-box;
    .box-item {
      width: 100%;
      background-color: #fff;
      &:nth-child(1) {
        margin-bottom: 20px;
      }
      &:nth-child(2) {
        height: 400px;
      }
      #numCensue {
        width: 100%;
        height: 90%;
      }
      .el-form-item {
        padding: 0;
        margin: 20px 0;
        margin-right: 20px;
        /deep/ .el-input__inner {
          height: 32px;
          line-height: 32px;
        }
      }
    }
  }
}

.btn-area {
  text-align: right;
  padding: 10px;
}
.tips {
  color: red;
  font-size: 12px;
  margin-left: 10px;
}
</style>
